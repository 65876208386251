<template>
  <div class="RndmWrdz">
    <h1>{{ msg }}</h1>
    <ul v-if="randomWords.length > 0">
      <li v-for="word in randomWords" :key="word">{{ word }}</li>
    </ul>
    <button @click="generateRandomWords">Random Words</button>
  </div>
</template>

<script>
export default {
  name: 'RndmWrdz',
  props: {
    msg: String
  },
  data() {
    return {
      randomWords: [],
      lists: [
        { name: 'Aktie', words: ['doen', 'zoeken', 'vinden', 'vertellen', 'ontdekken', 'proberen', 'schrijven', 'lezen', 'kopen', 'halen', 'leren', 'tekenen', 'schilderen', 'beschrijven', 'uitvoeren', 'delen', 'mediteren', 'vragen', 'oefenen', 'luisteren', 'kijken', 'voelen', 'bezoeken', 'veranderen', 'combineren', 'eten', 'koken', 'drinken', 'spelen'] },
        { name: 'Bijwoord', words: ['iets', 'over', 'iets nieuws', 'iets raars', 'met', 'in', 'een basis', 'een uitzondering', 'iets spannends', 'iets saais', 'tegengesteld aan', 'vergelijkbaar met', 'wat je leuk vind', 'waar je een hekel aan hebt', 'snel', 'uitgebreid'] },
        { name: 'Onderwerp', words: ['yoga', 'sport', 'psychologie', 'een land', 'taal', 'kunst', 'musea', 'cultuur', 'internet', 'filosofie', 'politiek', 'ambacht', 'sex', 'mensen', 'thrillers', 'wetenschap', 'erotiek', 'mode', 'eten', 'architectuur', 'constructies', 'boeken', 'websites', 'cursus', 'buiten leven', 'ruimte', 'science fiction', 'voertuigen', 'planten', 'reizen', 'dieren', 'geschiedenis', 'klimaat', 'meubels', 'interieur', 'voeding', 'sport', 'menselijk lichaam', 'mindfulness', 'drank', 'technologie', 'religie'] },
        { name: 'Tijd', words: ['vandaag', 'nu', 'ochtend', 'middag', 'avond', 'nacht', 'morgen', 'straks', 'deze week', 'ooit'] },
        { name: 'Wie', words: ['alleen', 'samen', 'anderen', 'onbekenden'] }
      ]
    }
  },
  methods: {
    generateRandomWords: function() {
      console.log('generateRandomWords');
      this.randomWords = [
        this.lists[0].words[Math.floor(Math.random() * this.lists[0].words.length)],
        this.lists[1].words[Math.floor(Math.random() * this.lists[1].words.length)],
        this.lists[2].words[Math.floor(Math.random() * this.lists[2].words.length)],
        this.lists[3].words[Math.floor(Math.random() * this.lists[3].words.length)],
        this.lists[4].words[Math.floor(Math.random() * this.lists[4].words.length)]
      ];
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: block;
  padding: 1rem;
  margin: 0 0 1rem;
  background-color: #c0ffee;
}
a {
  color: #42b983;
}
button {
  padding: 1rem;
}
</style>
